body, input, button, select, textarea {
  font-family: "Lato", sans-serif;
}

h4 {
  font-weight: 700;
}

#select_files {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

#select_files + label {
  padding: 10px 15px;
  margin-top: 2px;
  color: white;
  background-color: #00BCD4;
  border-color: transparent;
  font-size: 14px;
  line-height: 1.5;
  display: inline-block;
  cursor: pointer;
}

.upload_chevrons {
  margin-top: 20px;
  color: #f0f4f7;
  animation-name: chevrons_color;
  animation-duration: 8s;
  animation-iteration-count: infinite;
}

@keyframes chevrons_color {
  from {
    color: #f0f4f7;
  }
  to {
    color: #00BCD4;
  }
}
.fileselection {
  margin: 0 !important;
}

.files_selected {
  background-color: #f0f4f7 !important;
  color: #36414C !important;
}

.navbar-brand {
  min-height: 50px;
  padding: 0px;
}

.x-colophon.bottom .x-social-global {
  margin: 10px 0;
}

.x-social-global {
  font-weight: 400;
  line-height: 1;
}

.x-colophon.bottom .x-social-global a {
  margin: 0 1.25%;
  font-size: 21px;
}

.x-social-global a {
  display: inline-block;
  text-align: center;
  color: #113680;
}

.x-colophon.bottom {
  padding: 10px 0;
  font-size: 10px;
  text-align: center;
  color: #7a7a7a;
}

.x-colophon.bottom .x-colophon-content {
  margin: 30px 0 10px;
  font-weight: 400;
  letter-spacing: 2px;
  line-height: 1.3;
}

.x-colophon.bottom .x-nav {
  margin: 10px 0;
  line-height: 1.3;
}

.x-colophon.bottom .x-nav a {
  margin: 0 0.5em;
  display: inline-block;
  text-decoration: none !important;
}

.x-colophon.bottom .x-nav a span {
  display: block;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  line-height: 1.7;
  color: #113680;
}

.btn-primary {
  color: #fff !important;
  background-color: #00377f;
  border-color: #00377f;
}

.btn-primary:hover,
.btn-primary:focus {
  color: #ffd515;
  background-color: #00377f;
  border-color: #00377f;
}

.btn-primary:visited {
  color: #fff;
}

.btn-sm, .btn-group-sm > .btn {
  border-radius: 0px;
}

.btn {
  border-radius: 0px;
}

#new_request_btn .btn {
  width: 100%;
}

#new_request_btn {
  margin-top: 15px;
  margin-bottom: 15px;
}

.page-card.signup-form {
  max-width: 720px;
}

.form-row {
  margin-top: 10px;
}

.quotation-list-item,
.quotation-list-item:hover {
  background-color: #E1F5FE;
}

.quotation-list-item.Lost,
.quotation-list-item:hover.Lost,
.quotation-list-item.Cancelled,
.quotation-list-item:hover.Cancelled {
  background-color: #ededed;
}

.paid-list-item,
.paid-list-item:hover {
  background-color: #E8F5E9;
}

.returned-list-item,
.returned-list-item:hover {
  background-color: #ECEFF1;
}

.payment-list-item,
.payment-list-item:hover,
.overdue-list-item,
.overdue-list-item:hover {
  background-color: #ffafbd;
}

.unpaid-list-item,
.unpaid-list-item:hover {
  background-color: #ffccbc;
}

.quotation-list-item .pay-btn {
  border: 1px solid #A5D6A7;
  background-color: #A5D6A7;
  margin-right: 15px;
  margin-left: 30px;
  border-radius: 2px;
}

.quotation-list-item .pay-btn > a {
  color: #fff;
}

.quotation-list-item .paid-text {
  border: 1px solid #B0BEC5;
  background-color: #B0BEC5;
  color: #fff;
  margin-right: 15px;
  margin-left: 30px;
  border-radius: 2px;
}

.paid-list-item .invoice-status {
  border: 1px solid #A5D6A7;
  background-color: #A5D6A7;
  color: #fff;
  margin-right: 15px;
  margin-left: 30px;
  border-radius: 2px;
}

.returned-list-item .invoice-status {
  border: 1px solid #B0BEC5;
  background-color: #B0BEC5;
  color: #fff;
  margin-right: 15px;
  margin-left: 30px;
  border-radius: 2px;
}

.payment-list-item .pay-btn,
.overdue-list-item .invoice-status {
  border: 1px solid #ff153f;
  background-color: #ff153f;
  color: #fff;
  margin-right: 15px;
  margin-left: 30px;
  border-radius: 2px;
}

.unpaid-list-item .invoice-status {
  border: 1px solid #ff7043;
  background-color: #ff7043;
  color: #fff;
  margin-right: 15px;
  margin-left: 30px;
  border-radius: 2px;
}

.pdf_print a {
  color: #2196F3;
}

.web-list-item {
  border-bottom-color: #E0E0E0;
}

.web-list-item.transaction-list-item {
  padding-right: 10px;
}

.web-list-item.transaction-list-item:hover {
  background-color: #eaf1ff;
}

.web-list-item.transaction-list-item a:hover {
  -webkit-text-decoration: None !important;
          text-decoration: None !important;
}

.page-content.with-sidebar {
  padding-right: 30px;
}

.website-list .result {
  margin-top: 0px;
}

/*Projects Progress Bar*/
.bs-wizard {
  border-bottom: solid 1px #e0e0e0;
  padding: 0 0 10px 0;
}

.bs-wizard > .bs-wizard-step {
  padding: 0;
  position: relative;
}

.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
  color: #fff;
  font-size: 16px;
  margin-bottom: 5px;
}

.bs-wizard-stepnum:hover {
  color: #eaf1ff;
}

.bs-wizard > .bs-wizard-step .bs-wizard-info {
  color: #999;
  font-size: 14px;
}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot {
  position: absolute;
  width: 30px;
  height: 30px;
  display: block;
  background: #66bb6a;
  top: 45px;
  right: 50%;
  margin-top: -15px;
  margin-right: -15px;
  border-radius: 50%;
}

.bs-wizard > .bs-wizard-step > .right-dot {
  right: 100%;
  z-index: 9;
}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {
  width: 14px;
  height: 14px;
  border-radius: 50px;
  position: absolute;
  top: 6px;
  right: 8px;
}

.bs-wizard > .order_processed > .bs-wizard-dot:after {
  content: "1";
  color: #fff;
  text-align: center;
}

.bs-wizard > .translation_step > .bs-wizard-dot:after {
  content: "2";
  color: #fff;
  text-align: center;
}

.bs-wizard > .proofreading_step > .bs-wizard-dot:after {
  content: "3";
  color: #fff;
  text-align: center;
}

.bs-wizard > .quality_check_step > .bs-wizard-dot:after {
  content: "4";
  color: #fff;
  text-align: center;
}

.bs-wizard > .done_step > .bs-wizard-dot:after {
  content: "5";
  color: #fff;
  text-align: center;
}

.bs-wizard > .bs-wizard-step > .progress {
  position: relative;
  border-radius: 0px;
  height: 8px;
  box-shadow: none;
  margin: 20px 0;
}

.bs-wizard > .bs-wizard-step > .progress > .progress-bar {
  width: 0px;
  box-shadow: none;
  background: #66bb6a;
}

.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {
  width: 100%;
}

.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {
  width: 50%;
}

.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {
  width: 0%;
}

.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {
  width: 100%;
}

.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {
  background-color: #e6e3e2;
}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot.disabled {
  background-color: #e6e3e2;
}

.bs-wizard > .bs-wizard-step:first-child > .progress {
  right: 50%;
  width: 50%;
}

.bs-wizard > .bs-wizard-step:last-child > .progress {
  width: 50%;
}

.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot {
  pointer-events: none;
}

.progress {
  background-color: #e6e3e2;
}

.project-item {
  margin-top: 20px;
}

#addresses {
  margin-top: 100px;
}

.profile::-moz-placeholder {
  color: black;
}

.profile::placeholder {
  color: black;
}

#form-profile .form-control {
  margin-top: 5px;
}

#profile_picture {
  margin-bottom: 10px;
}

.required_field {
  border-color: #ef5350;
  border-width: 2px;
  border-style: solid;
}

#loadingDiv {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.timer-loader {
  position: absolute;
  margin: 0;
  top: 50%;
  right: 50%;
  margin-left: -50%;
  transform: translate(50%, -50%);
}

.upload_button {
  padding-top: 2px;
}

.upload_info {
  font-size: 10px;
}

#btn_upload {
  padding: 10px 15px;
  background-color: #00BCD4;
  color: #fff;
}

/* #btn_upload:disabled {
	background-color: #fff;
} */
.page-header-actions-block > a {
  padding: 4px 15px;
  font-size: 14px;
}

.page-content {
  min-height: 900px;
}

.sidebar-item > a {
  font-size: 14px;
}

.popover {
  max-width: 600px;
}

.not-bold {
  font-weight: normal;
}

.attachment {
  margin-top: 5px;
}

li > a[href="https://quote.davrontranslations.com"] {
  padding: 5px 10px 5px 10px;
  background-color: #fff;
  color: #00377f !important;
  border: 2px solid #00377f;
  margin-right: -10px;
  text-decoration: none;
  display: block;
}

li > a[href="https://quote.davrontranslations.com"]:hover {
  background-color: #FFDD0D;
}

/* Payment Panel */
.panel-subtitle > a {
  padding-right: 10px;
  text-decoration: none;
}

.red-panel {
  background-color: #ff443a;
  color: white;
  text-decoration: none;
  border: 2px solid transparent #ced5db;
}

.red-panel:hover {
  background-color: #ff1f13;
}

#decline-btn {
  text-decoration: none;
}

/* Boostrap override */
.control-label[for=comments] {
  text-align: right !important;
}

#decline-quotation {
  margin-bottom: 5px;
}

.payment-page-btn {
  width: 200px;
  height: 75px;
  background-color: #fff;
  color: #00377f;
  border: 1px solid #00377f;
  position: relative;
  padding: 5px;
  transition-duration: 0.4s;
}

@media (min-width: 768px) {
  .payment-page-btn {
    width: 700px;
    height: 120px;
  }
  .payment-methods img {
    max-height: 100px;
  }
}
.payment-page-btn:hover {
  background-color: #fdf7c0;
}

.payment-page-btn i {
  font-size: 45px;
  display: block;
  margin-bottom: 25px;
}

.payment-btn {
  width: 150px;
  height: 100px;
  background-color: #fff;
  color: #00377f;
  border: 2px solid #00377f;
  position: relative;
}

.payment-btn {
  transition-duration: 0.4s;
}

.payment-btn:hover {
  background-color: #FFDD0D;
}

.payment-btn i {
  font-size: 45px;
  display: block;
  margin-bottom: 25px;
}

.payment-btn img {
  margin-bottom: 25px;
}

.payment-btn span {
  position: absolute;
  bottom: 5%;
  width: 100%;
  right: 0%;
}

.payments-title {
  margin-bottom: 100px;
}

.payments-title h1 {
  font-size: 2rem;
}

.payment-methods {
  margin-bottom: 25px;
}

.payment-methods img {
  margin: auto;
  display: block;
}

@media (min-width: 768px) {
  #braintree-button img,
  #braintree-button .pay-btn-title {
    margin-top: 15px;
  }
}
@media (max-width: 768px) {
  #banktransfer-button img {
    max-height: 65px;
    margin-right: 25px;
  }
}
.pay-btn-title {
  font-weight: 600;
  float: right;
}

.pay-btn-subtitle {
  float: left;
}

.success-check > i {
  color: #66bb6a;
  font-size: 60px;
  margin: 20px 0px;
}

.checkout #submit-button {
  background-color: #78a300;
  border-color: #6a8f00;
}

.checkout #submit-button:hover {
  color: #f1ffca;
}

.success-page .new-btn,
.discard-btn {
  display: none;
}